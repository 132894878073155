@use '@angular/material' as mat;
@use 'base/typography' as typography;
@use 'base/colour-palettes' as palettes;

@tailwind base;
@tailwind components;
@tailwind utilities;

@import 'base/base';
@import 'base/components';
@import 'base/global-classes';
@import 'base/animations';
@import '3rd-party/quill';
@import 'src/app/modules/portal/components/shared/chart-utils/chart-utils';
@import '../app/modules/portal/components/measure/services/chart-tooltip';

@import '../../node_modules/quill/dist/quill.core.css';
@import '../../node_modules/quill/dist/quill.snow.css';

@import 'handsontable/styles/handsontable.min.css';
@import 'handsontable/styles/ht-theme-horizon.min.css';

$trace-theme: mat.m2-define-light-theme((
  color: (
    primary: palettes.$trace-palette-primary,
    accent: palettes.$trace-palette-accent,
    warn: palettes.$trace-palette-warn
  ),
  typography: typography.$trace-typography
));

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.elevation-classes();
@include mat.app-background();
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@include mat.all-component-themes($trace-theme);
@include mat.typography-hierarchy(typography.$trace-typography);

@import 'base/setting_material_variables';

@keyframes depth-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes depth-out {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0.5;
  }
}

::view-transition-old(root) {
  animation: depth-out 500ms cubic-bezier(0.4, 0, 0.2, 1) both;
  will-change: opacity, transform;
}

::view-transition-new(root) {
  animation: depth-in 300ms cubic-bezier(0, 0, 0.2, 1) both;
  will-change: opacity, transform;
}
